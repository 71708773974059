.end-call-panel{
    // position: absolute;
    // left:50%;
    min-width:300px ;
    width: 480px;
    background-color: transparent;
    box-shadow: none;
    .greeting-section,.free-trial-promo{
        background-color: $white;
        border-radius: 12px;
        box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #f7f7f7;
    }
    
    button{
        white-space: nowrap;
        font-size: 14px;
    }

    .greeting-section{
        text-align: center;
        padding: 40px;
        margin-bottom: 30px;
        font-size: 24px;
        width: 100%;

        h5{
            margin-bottom: 30px;
        }

        p{
            font-size: 14px;
            color: $nobel;
            margin-bottom: 30px;
        }

        .extra-options{
            display: flex;
            justify-content:center;
             
            button{
                margin: 0 20px;
            }
        }
     

        .return-to-call{
            border: none;
            background-color: transparent;
            cursor: pointer;

          *{
              vertical-align: middle;
          }

          i{
              display: inline-block;
          }

          span{
              font-size: 14px;
              color: $ceruleanBlue;
              margin-left: 10px;
          }
        }

    }

    .free-trial-promo{
        overflow: auto;
        padding: 25px 40px;
        width: 100%;

        div{
            width: 50%;
            float: left;

            p{
                margin-bottom: 30px;
                font-size: 16px;

                span{
                    font-weight: 600;
                }
            }
            
        }
        
        figure{
            width:50%;
            float: left;
           

        }

    }
    

  
}