.participant-video {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  position: absolute;
  bottom: 75px;
  right: 24px;
  z-index: 199;
  background-color: $cornflowerBlue;
  cursor: grab;
  
  @include mq(phone, max) {
    display:none;

  }

    video {
      position: relative;
      z-index: 1;
      object-fit: cover;
      transform: rotateY(180deg);
    }
    
  &.minimize{
    height: 60px;
    width: 60px;

    .peer-details{
      &::after {
        font-size: 16px;
  
      }
    }
    .size-controller {
        right: -5px;
        bottom: -5px;
      }
    video,img{
      height: 100%!important;
      width: 100%!important;
      border-radius: 50%;
    }
  }

  .size-controller {
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: $cornflowerBlue;
    position: absolute;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    z-index: 2;
    svg{
      transform: rotate(-90deg);
      top: 0;
      left: 0;
      object-fit: cover;
      pointer-events: none;
      bottom: 0;
      position: absolute;
      right: 0;
      margin: auto;
    }
  }

  .peer-details {
    height: 100%;
    width: 100%;

    &::after {
      font-size: 48px;

    }
  }

  video,
  img {
    height: 100% !important;
    width: 100% !important;
    border-radius: 50%;
  }

  video {
    position: relative;
    z-index: 1;
    object-fit: cover;

  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    pointer-events: none;
  }

  .peer-name {
    display: none;
  }
}