
//Styles for Feedback-panel
.feedback-panel {
    padding: 0;
  
    .topbar {
      padding: 20px 25px;
  
    }
  
    .rating {
      display: inline-block;
      margin: 0 auto 10px auto;
      font-size: 0;
      padding: 15px 25px;
  
      &::after {
        @include clearfix;
      }
  
      .star {
        display: none;
  
  
        &:checked+label>svg,
        &:checked~label>svg {
          width: 29px;
  
          -webkit-filter: drop-shadow(0px 0px 2px rgba(51, 208, 147, 0.32));
          filter: drop-shadow(2px 2px 2px rgba(51, 208, 147, 0.32));
  
          path {
            fill: url('#linearGradient-1');
            stroke: url('#linearGradient-1');
          }
        }
      }
  
      label {
        cursor: pointer;
        float: right;
        // margin-right: 5px;
        position: relative;
        height: 35px;
        width: 40px;
        vertical-align: middle;
        text-align: center;
  
        svg {
          width: 28px;
          height: auto;
          vertical-align: middle;
        }
  
      
  
        &:hover svg,
        &:hover~label>svg {
          width: 29px;
  
          -webkit-filter: drop-shadow(0px 0px 2px rgba(51, 208, 147, 0.32));
          filter: drop-shadow(2px 2px 2px rgba(51, 208, 147, 0.32));
  
          path {
            fill: url('#linearGradient-1');
            stroke: url('#linearGradient-1');
          }
        }
  
        @include tooltip;
       
  
      }
  
  
  
  
  
  
  
  
    }
  
    textarea {
      width: calc(100% - 50px);
      resize: none;
      margin: 0 auto;
      display: block;
      border-radius: 8px;
      padding: 20px;
      border: 2px solid $veryLightGrey;
      height: 200px;
  
      &::placeholder {
        color: $txt-color-tertiary;
      }
    }
  
    .footer {
      border: none;
      margin-top: 0;
  
      &::after {
        @include clearfix;
      }
  
      button {
        float: right;
      }
    }
  }