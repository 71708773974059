.shortcuts-panel {
    padding: 0 0 20px 0;
    width: 600px;

    .topbar {
        padding: 20px 25px;

        .close-btn {
            cursor: pointer;
        }
    }

    .content {
        li {
            display: flex;
            justify-content: space-between;
            padding: 0 25px;
            align-items: center;
            margin-bottom: 15px;

            .description {
                font-size: 18px;


            }

            .keys {
                display: inline-block;

                code {
                    font-size: 16px;
                    height: 36px;
                    display: inline-block;
                    min-width: 36px;
                    margin: 0 2px;
                    padding: 10px 10px;
                    text-align: center;
                    line-height: 16px;
                    background: linear-gradient(270deg, #F8F8F8 0%, #FFFFFF 49.48%, #F8F8F8 100%);
                    border: 1px solid #F0F0F0;
                    box-sizing: border-box;
                    box-shadow: 0px 2px 0px 1px rgba(125, 125, 125, 0.25);
                    border-radius: 4px;
                }
            }
        }
    }
}