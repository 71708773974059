.room-full-alert{
    z-index: 1;
    text-align: center;
    padding: 35px 0px;

    i{
        margin-bottom: 13px;
        display: inline-block;
    }
    h6{
        color:$tenne;
        font-size: 20px;
        margin-bottom: 8px;
    }
    p{
        font-size: 14px;
        margin-bottom: 20px;
    }
}