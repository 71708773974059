.push-notification {
    top: 0;
    width: 100%;
    background-color: $cornflowerBlue;
    position: fixed;
    z-index: 500;
    text-align: center;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;

    .notification-details {
        display: flex;
        justify-content: center;
        color: $white;
        align-items: center;
        font-size: 12px;
        p {
            font-size: 12px;
        }

        .enable-btn {
            background-color: $white;
            margin-left: 22px;
            padding: 5px 12px;
            border-radius: 50px;
            color: $cornflowerBlue;
            border: none;
            cursor: pointer;
            font: 12px;
        }

        a {
            font-size: 12px;
            color: $white;
            text-decoration: none;

            > * {
                vertical-align: middle;
                line-height: 20px;
            }

            span {
                text-decoration: underline;
            }

            i{
                font-size: 20px;
            }
        }
    }

    .close-btn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        line-height: 1;
        color: $white;
        font-weight: 600;
        cursor: pointer;
    }
}
