* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Metropolis;
  src: url("https://storage.googleapis.com/setmore-website/fonts/Metropolis-Light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("https://storage.googleapis.com/setmore-website/fonts/Metropolis-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("https://storage.googleapis.com/setmore-website/fonts/Metropolis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url("https://storage.googleapis.com/setmore-website/fonts/Metropolis-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.hide {
  display: none !important;
}

body {
  button {
    cursor: pointer ;
  }

  .awStartpage-new {
    z-index: 5;
    background-image: url("https://storage.googleapis.com/staging-connect/teleport/assets/0.4/images/website/hero-banner@2x.jpg");
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    padding: 30px;
    font-family: "Metropolis", Helvetica, sans-serif;
    text-align: center;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    * {
      font-family: inherit;
    }

    .logged-in-view {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 100%;
    }

    h1 {
      font-size: 44px;
      color: #fff;
      margin-top: 0;
      // margin-top: 150px;
      font-weight: 700 !important;

      span {
        color: #6285ff;
      }
    }

    .container {
      max-width: 850px;
      width: 100%;
      margin: 0 auto;
      background-color: #fff;
      text-align: left;
      padding: 30px 40px;
      margin-top: 60px;
      border-radius: 12px;
      box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.08);
      user-select: none;

      p {
        margin-bottom: 20px;
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }

      .input-wrapper {
        height: 64px;
        border: 1px solid #e3e8f5;
        border-radius: 64px;
        font-size: 0;
        display: flex;
        align-items: center;
        * {
          font-weight: 500;
        }

        span {
          display: inline-block;
          height: 100%;
          border-radius: 64px 0 0 64px;
          line-height: 64px;
          padding: 0 20px 0 20px;
          background-color: #f4f6fd;
          border-right: 1px solid #e3e8f5;

          font-size: 16px;
          vertical-align: middle;
        }

        input {
          height: 100%;
          width: 100%;
          line-height: 65px;
          vertical-align: middle;
          border: none;
          outline: none;
          font-size: 16px;
          padding: 0 20px;
          color: #353535;
          font-weight: 500;
          user-select: none;
          max-width: 300px;
        }

        button {
          font-size: 16px;
          font-weight: 600 !important;
          color: #fff;
          border-radius: 50px;
          background-color: #4684ff;
          padding: 15px 35px;
          border: none;
          outline: none;
          font-weight: 400;
          vertical-align: middle;
          max-height: 50px;
          margin-left: auto;
          margin-right: 10px;
          line-height: 1 !important;
          position: relative;
          .tooltip{
            padding:8px 10px;
            top: -35px;
            // border-radius: 8px;

            &::after{
              content: "";
              background-color: inherit;
              position: absolute;
              bottom:-5px;
              left: 50%;

              height: 10px;
              width: 10px;
              transform:translate(-50%,0) rotate(45deg);
            }
          }



          &:hover{
            .tooltip{
              display: block;

            }
          }
        }
      }

      .copy-link {
        border: none;
        outline: none;
        background-color: transparent;
        margin-top: 20px;

        svg {
          vertical-align: middle;
          width: 23px;
          height: auto;
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          color: #2763ce !important;
          font-weight: 500 !important;
          vertical-align: middle;
          text-decoration: underline;
          position: relative;
          top: initial;
          left: initial;
          right: initial;
        }
      }
    }

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #3d3d3d 0%, rgba(0, 0, 0, 0));

      .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        text-align: left;
        align-items: center;
        background-color: #fff;
        border-radius: 10px;

        .header {
          display: flex;
          border-bottom: 1px solid #efefef;
          padding: 20px;

          .teleport-logo {
            svg {
              width: 30px;
            }
          }

          .text {
            padding-left: 10px;

            h3 {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 5px;
            }

            p {
              font-size: 12px;
              font-weight: 400;
            }
          }

          .close {
            margin-left: auto;
            cursor: pointer;

            svg {
              width: 15px;

              path {
                stroke-width: 1px;
              }
            }
          }
        }

        .content {
          padding: 20px;
          height: 250px;
          overflow: auto;
          // h3{
          // 	text-align: center;
          // }
          p {
            margin-bottom: 10px;
            color: #969696;
            font-size: 14px;
          }
          ul {
            margin-bottom: 10px;
            color: #969696;
            font-size: 14px;
            padding-left: 30px;
            li {
              margin-bottom: 10px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .footer {
          padding: 20px;
          text-align: right;

          .agree {
            font-size: 14px;
            color: #fff;
            background-color: #4684ff;
            padding: 7px;
            border: none;
            outline: none;
            font-weight: 400;
            vertical-align: middle;
            min-width: 100px;
            border-radius: 3px;

            &.disabled {
              background-color: #9e9e9e;
              cursor: not-allowed !important;
            }
          }
        }
      }
    }
  }
}
