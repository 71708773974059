.user-actions {
  z-index: 10;
  position: relative;

  [class*="btn"] {
    width: 40px;
    height: 40px;
    cursor: pointer;
    // @include mq(phone, max) {
    //   transform: scale(0.5);

    // }
  }

  [class*="holder"] {
    position: fixed;
    z-index: 10;
    transition: all 0.2s ease-in-out;
  }

  &.chat-open {
    .btn-chat-holder,
    .btn-options-holder {
      right: 399px;
    }
  }
}

.btn {
  &-chat-holder {
    top: 24px;
    right: 24px;
    @include mq(phone, max) {
      transform: scale(0.7);
    }

    button {
      &::after {
        // display: none;
        position: absolute;
        height: 22px;
        width: 22px;
        left: -15px;
        top: -6px;
        font-size: 12px;
        color: $white;
        background-color: $cornflowerBlue;
        border-radius: 50%;
        line-height: 22px;
        font-weight: 600;
      }

      &.notification::after {
        content: attr(data-msgcount);
      }
    }
  }

  &-lock-holder {
    right: 50%;
    top: 45px;
    transform: translate(0, -50%);

    button {
      svg {
        width: 18px;
        height: auto;

        path {
          fill: $white;
        }
      }

      &.disabled {
        background-color: $veryLightGrey;
        cursor: not-allowed;
      }
    }
  }

  &-options-holder {
    bottom: 24px;
    right: 24px;
    @include mq(phone, max) {
      transform: scale(0.7);
    }

    ul {
      right: 0;
      left: initial;
      bottom: 45px;
      top: initial;
      transform: translateY(6px);
      max-height: initial;

      li {
        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }

    &.opened {
      ul {
        transform: translateY(0px);
      }
    }
  }

  &-sharescreen-holder {
    bottom: 24px;
    left: 24px;
    @include mq(phone, max) {
      transform: scale(0.7);
    }

    [class*="btn-ctrl"] {
      &::after {
        position: relative;
        content: attr(data-sharescreen);
        margin-left: 56px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.79;
        letter-spacing: -0.18px;
        color: $white;
        white-space: nowrap;
        @include mq(phone, max) {
          display: none;
        }
      }
    }
  }

  &-invite-holder {
    top: 24px;
    left: 24px;
    white-space: nowrap;
    // width: 82px;

    @include mq(phone, max) {
      transform: scale(0.7);
    }

    button {
      margin-right: 2px;

      &.invite-btn {
        border-top-right-radius: initial;
        border-bottom-right-radius: initial;
      }

      &.lock-btn {
        border-top-left-radius: initial;
        border-bottom-left-radius: initial;
      }
    }

    &.global-room {
      button {
        &.invite-btn {
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
        }

        &.lock-btn {
          display: none;
        }
      }
    }
  }
}
