.whitelabel-header{
    position: fixed;
    width: 100%;
    z-index: 300;

    display: flex;
    align-items: center;
    top: 0;


    .brand-logo{
        width: 180px;
        height: 90px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        position: absolute;
        left: 47px;
        top: 0;
       
        padding: 15px 20px;
        text-align: center;
        img{
            
            width: 100%;
            height: 100%;
            object-fit: contain;
         
        }
        &.img-fetched{
            background-color: $white;
        }

    }

    .sign-in-btn{
        position: absolute;
        right: 17px;
        top:17px;
    }

  


    .profile-settings {
        @extend %profile-settings;
        position: absolute;
        right: 47px;
        top:27px;
        ul{
            top:40px;
            right: -5px;

        }
 
 
     }


}