

@-webkit-keyframes chat-notify {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes chat-notify {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.chat-notification {
  position: fixed;
  right: 80px;
  top: 25px;
  width: 350px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 24px;
  cursor: pointer;
  animation: chat-notify 0.5s 1;
  z-index:300;

.message {
    .message-details {
      margin-bottom: 5px;

      &::after {
        @include clearfix;
      }


      i {
        display: inline-block;
        transform: none;
        vertical-align: middle;
        height: 26px;
        width: 26px;
        margin-right: 10px;
        border-radius: 50%;
        position:relative;

       .peer-details{
         height: 100%;
         width:100%;
         transform: none;
         top:initial;
         left:initial;
       }
       .peer-name{
        display: none;
      }
      }

      .sender-name {
        font-size: 14px;
        line-height: 25px;
        font-weight: 600;
        color: #777777;
        vertical-align: middle;

      }

      .time-stamp {
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: $txt-color-tertiary;
        float: right;
      }
    }

    .message-content {
      padding: 0 20px 0 37px;
      font-size: 14px;
      margin-bottom: 25px;
      user-select: text;
      line-height: 1.4;
      // max-height: 2.8em;
      overflow: hidden;
      margin-bottom: 5px;
      position: relative;
      color: $white;
      text-overflow: ellipsis;
      white-space: nowrap;


      // &:before {
      //   content: "...";
      //   position: absolute;
      //   right: 0.3em;
      //   bottom: 0px;
      //   font: inherit;
      // }

      // &:after {
      //   content: "";
      //   position: absolute;
      //   right: 0px;
      //   width: 1em;
      //   height: 1em;
      //   margin-top: 0.2em;
      //   background-color: rgb(255, 255, 255);
      // }

    }
  }
}
