.invite-panel {
    width: 480px;
    padding: 0;

    .topbar {
        padding: 20px 18px;
    }

    .dropdown-primary {
        margin: 20px auto 40px auto;
        outline: none;

        .input-field {
            padding: 2px;
            max-height: 150px;
            min-height: 48px;
            cursor: default;
            height: initial;
            padding: 2px 2px 0 2px;
            text-overflow: initial;
            overflow: auto;
            white-space: initial;

            &.disabled {
                pointer-events: none;
                border-color: $whisper;
                background-color: $aliceBlue;
                
                input {
                    background-color: inherit;
                }
             }

            .search-icon {
                display: inline-block;
                vertical-align: middle;
                line-height: 44px;
                margin: 0 0 0 20px;

                svg {
                    vertical-align: middle;
                }
            }


            ul {
                display: inline-block;
                vertical-align: middle;



                li {
                    line-height: 44px;
                    float: left;
                    margin: 0 2px 2px 0;



                    &.tags {
                        background-color: $skyblue;
                        padding: 0px 10px;
                        border-radius: 5px;
                        display: none;

                        span {
                            color: $tag-text-color;
                            margin-right: 10px;

                        }

                        .close-icon {
                            cursor: pointer;

                            svg {
                                vertical-align: middle;
                                width: 12px;
                                height: auto;

                            }
                        }
                    }

                    input {
                        height: 100%;
                        vertical-align: middle;
                        padding: 0 0 0 20px;

                    }
                }

            }

            &.input-on {
                .search-icon {
                    display: none;
                }

                .tags {
                    display: block;
                }
            }


            input {

                border: none;
                width: 220px;
                border-radius: 0;
            }

        }

        ul.contacts-list {
            height: initial;
            max-height: 140px;
            padding: 15px 10px;

            li.contacts {
                padding: 10px 60px;
                position: relative;
                line-height: 1;


                code {
                    height: 40px;
                    width: 40px;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    border-radius: 50%;
                    // background-color: $whisperLight;

                    &.guest {
                        background-color: $whisperLight !important;


                    }

                    &::after {
                        content: attr(data-contact-initial);
                        text-transform: capitalize;
                    }

                    svg {
                        width: 20px;
                        height: auto;
                        position: relative;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        position: relative;
                        z-index: 1;
                    }


                    &::after,
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, 50%);
                        transform: translate(-50%, -50%);

                    }

                }


                $colors: #00c882,
                #8686ca,
                #f7ac44,
                #118eae,
                #996231,
                #ff4519;
                $repeat: length($colors); // How often you want the pattern to repeat.

                @for $i from 1 through $repeat {
                    $randomNumber: random(length($colors));

                    &:nth-child(#{length($colors)}n + #{$i}) code {
                        background: lighten(nth($colors, $randomNumber), 30%);
                        color: darken(nth($colors, $randomNumber), 10%);
                    }
                }

                p {
                    line-height: 1;
                    margin-bottom: 5px;
                    font-size: 16px;
                    color: $txt-color-secondary;
                }

                span {
                    line-height: 1;
                    vertical-align: top;
                    font-size: 14px;
                    color: $txt-color-quaternary;
                }


                &.no-results {
                    height: 108px;

                    &:hover {
                        background-color: transparent;
                    }

                    code {
                        transform: translate(-50%, -65%);
                        left: 50%;

                        svg {
                            width: 28px;
                            height: auto;
                        }

                        &::after {
                            content: "no-results";
                            position: absolute;
                            top: 55px;
                            color: $txt-color-tertiary;


                        }
                    }

                }

            }
        }

    }

    .other-options {
        background-color: $whiteSmoke;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        // position: absolute;
        // bottom: 0;
        // width: 100%;

        .tab-navigation {
            border-top: 1px solid $border-color-primary;
            border-bottom: 1px solid $border-color-primary;

            &::after {
                @include clearfix;
            }

            .tabs {
                float: left;
                width: 50%;
                text-align: center;
                line-height: 40px;
                font-size: 12px;
                font-weight: 600;
                cursor: pointer;

                &.selected {
                    border-bottom: 2px solid $cornflowerBlue;
                    color: $inkblue;
                }

            }
        }

        .options-info {
            height: 140px;
            padding: 20px;

            .url-info {
                color: $txt-color-secondary;

                span {
                    font-size: 14px;
                    font-weight: 600;
                    display: table;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 15px;
                    padding: 0 20px 0 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                }

                .copy-btn {
                    border: none;
                    background: transparent;
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: attr(data-copy);
                        position: absolute;
                        white-space: nowrap;
                        left: 35px;
                        top: 1px;
                        font-size: 14px;
                        color: $inkblue;
                        text-decoration: underline;
                    }

                    svg {
                        vertical-align: middle;

                    }

                    &:hover {
                        .tooltip {
                          display: block;
                    
                        }
                    }
                    
                    &.dial-in-icon {
                

                        svg {
                              transform: rotate(-135deg);
                              width: 25px;
                              height: auto;

                            path {
                                stroke: $veryLightGrey;
                                fill: transparent;
                                stroke-width: 2px;
                            }
                        }


                    }
                }
            }


        }

    }

    .invite-confirmation {
        height: 184px;
        padding: 25px;
        text-align: center;

        .btn-primary {
            margin-top: 30px;
        }
    }

}