//Common Styles for entry point and call settings
.entry-point,
.call-settings {
  .container {
    .preview-pannel {
      background-color: $light-black;
      position: relative;
      border-radius: 8px;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        opacity: 1;
        position: relative;
        z-index: 1;
        transform: rotateY(180deg);
      }

      .device-status {
        visibility: visible;
        position: absolute;
        white-space: nowrap;
        z-index: 0;
        top: calc(50% - 15px);
        left: 50%;
        transform: translate(-50%, -50%);
        color: $txt-color-tertiary;
      }

      &.camera-off {
        video {
          // visibility: hidden;
          // z-index: -1;
          opacity: 0;
          pointer-events: none;
        }

        .device-status {
          // visibility: visible;
        }
      }
    }
  }
}

//Styles for entry point.
.entry-point {
  padding: 0;
  text-align: center;
  width: 480px;
  top: calc(50% + 30px);

  .container {
    width: calc(100% - 16px);
    height: 250px;
    margin: 8px auto 0 auto;
    border-radius: 8px;
    position: relative;

    .preview-pannel {
      width: 100%;
      height: 100%;
      z-index: 1;

      .video-trigger {
        position: absolute;
        top: calc(50% + 10px);
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .device-controls {
    position: absolute;
    bottom: 15px;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 0);

    [class*="btn-ctrl"] {
      margin: 0 8px;
    }
  }

  .more-options {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;

    ul {
      right: 0;
      left: initial;
      max-height: initial;

      li {
        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }

  h6 {
    color: $txt-color-tertiary;
    margin: 40px 0 0 0;
  }

  .url-details {
    margin: 35px 0 0 0; // when participant count is fixed, change back to 20px
    padding: 0 20px;

  }

  .btn-primary {
    margin: 40px 0 45px 0; // when participant count is fixed, change back to 30px 0 45px 0;
  }

  .back-btn {
    display: table;
    margin: auto;
  }

  .url-details {
    .btn-copy-link {
      width: 25px;
      height: 15px;
      background-color: transparent;
      border: none;
      vertical-align: middle;
      cursor: pointer;
      position: relative;

      &:hover {

        .tooltip {
          display: block;
        }
      }
    }

    span {
      font-size: 14px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 80%;
      line-height: 25px;
      margin-left: 10px;
      vertical-align: middle;
      user-select: text;
    }
  }

  .footer {
    text-align: left;
    padding: 30px;
  }
}

//Styles for call setting
.call-settings {
  padding: 0;
  text-align: left;

  .container {
    min-width: 420px;
    margin: 0 auto;
    display: flex;
    padding: 0 30px;
    align-items: center;

    .preview-pannel {
      width: 500px;
      height: 315px;
    }

    .setting-pannel {
      width: 285px;
      margin-left: 25px;

      .device-details {
        margin: 0px 0 10px 0;

        .device-icon {
          display: inline-block;

          svg {
            width: 20px;
            vertical-align: middle;
            margin-right: 10px;

            path {
              fill: $device-icon-primary;
            }
          }
        }

        label {
          font-size: 12px;
          line-height: 26px;
          font-weight: 600;
          margin: 0;
          color: $txt-color-secondary;
          vertical-align: middle;
          &.switch{
            margin-top: 10px;
          }
        }

        .dropdown-primary {
          width: 100%;
          margin: 5px 0 0 0;

          input {
            width: 100%;
          }
        }

        .preview-pannel {
          width: 500px;
          height: 315px;
        }

        .signal-strength {
          // width: 360px;
          display: inline-block;
          margin: 10px 0 0 2px;
          font-size: 0;
          position: relative;
          min-height: 8px;

          [class*="indicator"] {
            overflow: hidden;
            white-space: nowrap;

            code {
              display: inline-block;
              height: 8px;
              width: 8px;
              margin: 0 5px 0 0;
              border-radius: 50%;
            }
          }

          .indicator {
            code {
              background-color: $signal-indication-off;
            }

            &-fill {
              position: absolute;
              left: 0;
              top: 0;
              width: 50%;
              transition: width 0.2s;

              code {
                background-color: $signal-indication-on;
              }
            }
          }
        }

        &:last-child {
          margin: 0;
        }

        .test-audio {
          display: inline-block;
          font-weight: 400;
          margin: 10px 0 0 0px;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
  }

  .footer {
    &::after {
      @include clearfix;
    }

    button {
      float: right;
    }
  }
}

//Styles for device permissions
.device-permissions {
  padding: 20px 15px;
  width: 480px;
  top: 40%;

  .topbar {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    .h5 {
      float: left;
      line-height: 1.2;
    }

    .close-icon {
      float: right;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin: 15px 0 0 0;
  }

  p {
    margin: 0 0 20px 0;
    padding: 0 15px;

    span {
      font-weight: 700;
    }
  }
}